import { RGBAToRGB } from "../../../utils";

export const colors = (key, alpha = 1, flatten) => {
  const palette = {
    background: `rgba(040,036,034,${alpha})`, //rgb(60,56,54)
    chrome: `rgba(082,072,064,${alpha})`, //rgb(007,008,024)
    foreground: `rgba(251,241,199,${alpha})`, //rgb(255,255,255)
    link: `rgba(214,093,014,${alpha})`, //rgb(254,94,98)
    accent: `rgba(069,133,136,${alpha})`, //rgb(73, 128, 215)
    highlight: `rgba(215,153,033,${alpha})`, //rgb(220,193,40)
    success: `rgba(104,157,106,${alpha})`, //rgb(146,179,5)
    text: `rgba(251,241,199,${alpha})`, //rgb(235,235,245)
    warning: `rgba(152,151,026,${alpha})`, //rgb(250,219,20)
    danger: `rgba(204,036,029,${alpha})`, //rgb(255,120,117)
    shadow: `rgba(151,141,099,${alpha})`, //rgb(047,048,084)
  };
  if (!key) return palette;

  const color = palette[key] ? palette[key] : palette.foreground;
  return flatten ? RGBAToRGB(color, [17, 18, 24]) : color;
};
