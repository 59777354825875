import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { renderToString } from "react-dom/server";

import { Wrapper as MapWrapper } from "@googlemaps/react-wrapper";

import style from "./style.json";

import useDeepCompareEffect from "use-deep-compare-effect";

import styled, { useTheme } from "styled-components";

import { Link } from "components";
import { Icon } from "../images";
import { Chiclet as Chic } from "../Chiclet";

const Chiclet = styled(Chic)`
  z-index: 99999;
`;

const MapUpdate = styled(Chiclet)`
  position: absolute;
  left: 1rem;
  bottom: 0.5rem;
`;

const MapContainer = styled.div`
  ${({ height }) => `
  height: ${height};
  overflow: hidden;
`}
`;

const Title = styled.h1`
  ${({ color }) => `
  font-size: 1.2rem;
  color: ${color};
`}
`;

const InfoWindow = ({ color, slug, title }) => {
  return (
    <Title color={color}>
      <a href={`/places/${slug}`}>{title}</a>
    </Title>
  );
};

const Marker = ({ map, isCurrent, position, handleClick, slug, title }) => {
  const [marker, setMarker] = useState();
  const { colors, icons } = useTheme();

  const infowindow = new google.maps.InfoWindow({
    content: renderToString(
      <InfoWindow color={colors("link")} slug={slug} title={title} />
    ),
  });

  const options = {
    map,
    position,
    title,
    icon: {
      path: icons.default,
      fillColor: isCurrent ? colors("link") : colors("text", 0.5),
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 1.75,
      anchor: new google.maps.Point(15, 20),
    },
  };

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    let listener;
    if (marker) {
      marker.setOptions(options);
      listener = marker.addListener("click", () => {
        handleClick(marker, options);
      });
    }
    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [marker, options]);

  return null;
};

export const GoogleMap = memo(
  ({
    center,
    children,
    className,
    current,
    height = "30vh",
    locations = [],
    onClick,
    onDragEnd = () => true,
    onIdle,
    showUpdate,
    updateMap,
    variant = 0,
    zoom = 14,
    ...rest
  }) => {
    const [mapMoved, setMapMoved] = useState();
    const [map, setMap] = useState(null);
    const mapRef = useRef();
    const onLoad = useCallback((map) => setMap(map), []);
    const { colors } = useTheme();

    const dragEnd = ({ center }) => {
      setMapMoved(center);
      onDragEnd(center);
    };

    const doUpdate = () => {
      if (!mapMoved.lat || !mapMoved.lng) return;
      updateMap(mapMoved);
    };

    useDeepCompareEffect(() => {
      if (map) {
        map.setOptions(rest);
        map.mapTypes.set(
          "Pinmonkey",
          new google.maps.StyledMapType(style[variant], { name: "Pinmonkey" })
        );
      }
    }, [map, rest]);

    useEffect(() => {
      if (mapRef.current && !map) {
        setMap(
          new window.google.maps.Map(mapRef.current, {
            mapTypeControlOptions: {
              mapTypeIds: ["Pinmonkey"],
            },
            mapTypeId: "Pinmonkey",
            center: center,
            zoom: zoom,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
          })
        );
      }
    }, [mapRef, map]);

    useEffect(() => {
      if (map) {
        map.panTo(center);
      }
    }, [center]);

    useEffect(() => {
      if (!map || locations.length === 1) return;
      let bounds = new google.maps.LatLngBounds();
      const length =
        locations.length > 1
          ? locations.length > 5
            ? 4
            : locations.length
          : 0;
      for (let i = 0; i < length; i++) {
        let lat = locations[i].latitude;
        let lng = locations[i].longitude;
        if (!lat || !lng) return;
        bounds.extend(new google.maps.LatLng(Number(lat), Number(lng)));
      }
      map.fitBounds(bounds);
    }, [map, locations]);

    useEffect(() => {
      if (map) {
        ["click", "idle"].forEach((eventName) =>
          google.maps.event.clearListeners(map, eventName)
        );
        if (onClick) {
          map.addListener("click", onClick);
        }

        if (onIdle) {
          map.addListener("idle", () => onIdle(map));
        }

        map.addListener("dragend", () => dragEnd(map));
      }
    }, [map, onClick, onIdle]);

    return (
      <>
        <MapContainer
          className={className}
          onLoad={onLoad}
          ref={mapRef}
          height={height}
        >
          {Children.map(children, (child) => {
            if (isValidElement(child)) {
              // set the map prop on the child component
              return cloneElement(child, { map });
            }
          })}
        </MapContainer>
        {showUpdate && (
          <MapUpdate onClick={doUpdate}>
            <Icon icon="crosshairs" fill={colors("background")} />
          </MapUpdate>
        )}
      </>
    );
  }
);

export const Map = ({ locations = [], current, ...rest }) => {
  //if (!locations || locations.length === 0) return null;
  const [position, setPosition] = useState({
    latitude: 41.85003,
    longitude: -87.65005,
  });

  current = current || locations[0]?.id;

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setPosition(position.coords);
    });
  }, []);

  return (
    <MapWrapper apiKey={process.env.GOOGLE_API_KEY} libraries={["places"]}>
      <GoogleMap
        {...rest}
        locations={locations}
        current={current}
        center={{
          lat: Number(
            locations.find((l) => l?.id === current)?.latitude ||
              position.latitude
          ),
          lng: Number(
            locations.find((l) => l?.id === current)?.longitude ||
              position.longitude
          ),
        }}
      >
        {locations.map((place, i) => {
          return (
            <Marker
              key={i + place.latitude}
              position={{
                lat: parseFloat(place.latitude),
                lng: parseFloat(place.longitude),
              }}
              handleClick={rest.markerClick}
              slug={place?.Location?.slug}
              isCurrent={current === place.id}
              title={place?.Location?.name}
            />
          );
        })}
      </GoogleMap>
    </MapWrapper>
  );
};
