import { RGBAToRGB } from "../../../utils";

export const colors = (key, alpha = 1, flatten) => {
  const palette = {
    background: `rgba(251,241,220,${alpha})`, //rgb(239,235,235)
    chrome: `rgba(254,250,235,${alpha})`, //rgb(254,250,255)
    foreground: `rgba(060,056,054,${alpha})`, //rgb(38,38,38)
    link: `rgba(214,093,014,${alpha})`, //rgb(254,94,98)
    accent: `rgba(069,133,136,${alpha})`, //rgb(73, 128, 215)
    highlight: `rgba(215,153,033,${alpha})`, //rgb(220,193,40)
    success: `rgba(104,157,106,${alpha})`, //rgb(146,179,5)
    warning: `rgba(152,151,026,${alpha})`, //rgb(250,219,20)
    danger: `rgba(204,036,029,${alpha})`, //rgb(255,120,117)
    text: `rgba(038,038,038,${alpha})`, //rgb(38,38,38)
    shadow: `rgba(051,041,000,${alpha})`, //rgb(38,38,38)
  };

  if (!key) return palette;

  const color = palette[key] ? palette[key] : palette.foreground;
  return flatten ? RGBAToRGB(color, [255, 255, 255]) : color;
};
